import { useUnreadChannels } from "@hooks/use-chat";

import MenuIcon from "@components/Icons/MenuIcon";
import { NotificationBubble } from "@components/NotificationBubble";

const BurgerMenu = ({ displayMobileSideBar }) => {
  const { hasUnread } = useUnreadChannels();

  const renderNotificationBubble = hasUnread && (
    <NotificationBubble
      size="medium"
      className="absolute top-1.5 right-1.5 border-2 border-white"
    />
  );

  return (
    <div className="sm:hidden px-3 py-3 flex">
      <button
        onClick={displayMobileSideBar}
        className="relative -ml-2.5  h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
        aria-label="Open sidebar"
      >
        <MenuIcon className="h-6 w-6" />
        {renderNotificationBubble}
      </button>
    </div>
  );
};

export default BurgerMenu;
