import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { BillingRatesProvider } from "@contexts/billing-rates";
import { MessagingProvider } from "@contexts/messaging";
import { RestrictedAccessProvider } from "@contexts/restrictedAccess";

import AIChatButton from "@components/AIChat/AIChatButton";
import { CoachBillableRequestsModal } from "@components/BillableRequest/BillableRequestsModal";
import FreeTrialConversionContainer from "@components/Client/FreeTrialConversionContainer";
import ContentListLayout from "@components/Layout/ContentListLayout";
import StickyContainerLayout from "@components/Layout/StickyContainerLayout";
import VerifyEmailBanner from "@components/Layout/VerifyEmailBanner";
import { PageLoadingSpinner } from "@components/LoadingSpinner";
import MetaHead from "@components/MetaHead";
import PrivateTodosSideModal from "@components/Todos/PrivateTodosSideModal";

import AppHeader, { AppHeaderProps } from "./AppHeader";
import BurgerMenu from "./BurgerMenu";
import FormWrapper from "./FormWrapper";
import NavigationBar from "./NavigationBar";

export interface AppFrameProps extends AppHeaderProps {
  isLoading?: boolean;
  enableBrowserWarnings?: boolean;
  children?: ReactNode;
  contentListClassName?: string;
  contentListContainerClassName?: string;
  showOnlyDropdownOnHeader?: boolean;
  showHeader?: boolean;
}

const AppFrame: FC<AppFrameProps> = ({
  variant = "default",
  title,
  customTitle,
  descriptionIcon,
  description,
  headerButtons,
  actions,
  isLoading,
  formMethods,
  extraHeaderContent,
  openHeaderDropdown,
  setOpenHeaderDropdown,
  enableBrowserWarnings,
  children,
  contentListClassName,
  contentListContainerClassName,
  showOnlyDropdownOnHeader = false,
  showHeader = true,
}) => {
  const router = useRouter();
  const { showTrialConversion } = router.query;
  const { showPaymentWall, hasAccountPaymentMethod, hasTrialEnded } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const [showFreeTrialConversion, setShowFreeTrialConversion] = useState(false);

  const displayMobileSideBar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (showTrialConversion === "true" && !hasTrialEnded) {
      setShowFreeTrialConversion(true);
    }
  }, [hasTrialEnded, showTrialConversion]);

  const handleClickTrialButton = useCallback(() => {
    if (isOpen) setIsOpen(false);
    setShowFreeTrialConversion(true);
  }, [isOpen]);

  const handleDismissFreeTrialConversion = useCallback(
    () => setShowFreeTrialConversion(false),
    []
  );

  const content = (
    <StickyContainerLayout>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {showHeader && (
            <AppHeader
              variant={variant}
              title={title}
              customTitle={customTitle}
              descriptionIcon={descriptionIcon}
              description={description}
              headerButtons={headerButtons}
              actions={actions}
              extraHeaderContent={extraHeaderContent}
              formMethods={formMethods}
              openHeaderDropdown={openHeaderDropdown}
              setOpenHeaderDropdown={setOpenHeaderDropdown}
              showOnlyDropdownOnHeader={showOnlyDropdownOnHeader}
            />
          )}
          <ContentListLayout
            className={contentListClassName}
            containerClassName={contentListContainerClassName}
          >
            {children}
            <FreeTrialConversionContainer
              variant="modal"
              hasAccountPaymentMethod={hasAccountPaymentMethod}
              show={showFreeTrialConversion || showPaymentWall}
              onDismiss={handleDismissFreeTrialConversion}
            />
          </ContentListLayout>
        </>
      )}
    </StickyContainerLayout>
  );

  return (
    <RestrictedAccessProvider>
      {title && <MetaHead title={title} />}
      <MessagingProvider>
        <BillingRatesProvider>
          <div className="h-screen flex overflow-hidden bg-white font-roboto">
            <NavigationBar
              isOpen={isOpen}
              displayMobileSideBar={displayMobileSideBar}
              handleClickTrialButton={handleClickTrialButton}
            />
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <BurgerMenu displayMobileSideBar={displayMobileSideBar} />

              {variant === "main" ||
              variant === "details" ||
              variant === "record" ||
              (variant === "form" && !enableBrowserWarnings) ? (
                <>
                  {variant === "main" && <VerifyEmailBanner />}
                  {content}
                </>
              ) : variant === "form" && enableBrowserWarnings ? (
                <FormWrapper formMethods={formMethods}>{content}</FormWrapper>
              ) : (
                <>{children}</>
              )}
            </div>
            <div className="fixed z-40 bottom-6 right-6 flex gap-2">
              <AIChatButton />
              <CoachBillableRequestsModal />
              <PrivateTodosSideModal />
            </div>
          </div>
        </BillingRatesProvider>
      </MessagingProvider>
    </RestrictedAccessProvider>
  );
};

export default AppFrame;
