import { FC, useRef, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { useCheckScreenSize } from "@hooks/use-window-size";
import { SVGIconProps } from "@lib/shared-types";
import { useOutsideClick } from "@lib/utils/use-outside-click";

import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";
import Transition from "@components/Transition";

import SidebarButton, { SidebarButtonProps } from "./SidebarButton";

interface UnfoldingSidebarButtonWrapperProps {
  extended?: boolean;
  title: string;
  Icon: FC<SVGIconProps>;
  navItems: SidebarButtonProps[];
  hasUnread?: boolean;
  unreadCount?: number;
}

const UnfoldingSidebarButtonWrapper: FC<UnfoldingSidebarButtonWrapperProps> = ({
  extended,
  title,
  Icon,
  navItems = [],
  hasUnread,
  unreadCount,
}) => {
  const { pathname } = useRouter();
  const { isMD, isLG } = useCheckScreenSize();
  const isSelected = navItems.some((item) => item.href === pathname);
  const [collapsed, setCollapsed] = useState(!isSelected);
  const menuRef = useRef<HTMLElement>(null);

  useOutsideClick(menuRef?.current, () => {
    if (isMD && !isLG) setCollapsed(true);
  });

  const renderHeader = (
    <SidebarButton
      label={title}
      Icon={Icon}
      extended={extended}
      className={classNames(
        "!text-grey-500",
        collapsed
          ? "focus:!bg-transparent group-hover:!text-black-ink"
          : "hover:!bg-transparent focus:!bg-grey-900"
      )}
      hasUnread={collapsed && hasUnread}
      unreadCount={unreadCount}
    />
  );

  const renderNavItems = (className?: string) => (
    <div className={classNames("flex flex-col gap-1", !collapsed && "-mt-2")}>
      {navItems.map((item, index) => (
        <SidebarButton
          key={`nav-item-${index}`}
          {...item}
          className={classNames(
            "bg-transparent mx-2 !py-2 !px-3 md:!px-1 lg:!px-3",
            className
          )}
        />
      ))}
    </div>
  );

  const transitionClasses = "transition ease-in-out duration-150";

  return (
    <div className="mt-1 lg:w-full">
      <div
        className={classNames(
          "rounded-md sm:hidden lg:block",
          transitionClasses,
          collapsed ? "hover:bg-white" : "bg-grey-900 pb-2"
        )}
      >
        <div className="group">
          <CollapsedHeader
            collapsed={collapsed}
            toggleCollapsed={setCollapsed}
            leftElement={renderHeader}
            iconClassNames={classNames("mr-2 text-grey-500", transitionClasses)}
          />
        </div>
        {!collapsed && renderNavItems()}
      </div>
      <div className="hidden md:block lg:hidden relative">
        <div onClick={() => setCollapsed(false)}>{renderHeader}</div>
        <Transition
          ref={menuRef}
          show={!collapsed}
          className={classNames(
            "z-50 absolute pt-4 pb-2 left-0 rounded-md shadow-lg w-48 md:w-auto lg:w-48 bg-grey-950 ring-1 ring-black/5 md:show"
          )}
        >
          {renderNavItems("!text-black-ink hover:!bg-grey-950")}
        </Transition>
      </div>
    </div>
  );
};

export default UnfoldingSidebarButtonWrapper;
