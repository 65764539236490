import { Transition } from "@headlessui/react";
import compact from "lodash/compact";

import { useAuth } from "@contexts/auth";
import { useGetBillableRequestNumbers } from "@hooks/data/billable-request";
import useAccessType from "@hooks/use-access-type";
import { useUnreadChannels } from "@hooks/use-chat";
import useHasFeature from "@hooks/use-has-feature";
import { FeatureNames } from "@lib/constants/featureNames";
import { isDevOrPreviewEnvironment } from "@lib/utils";

import { useGetBillableReviewerConditional } from "@components/BillableRequest/BillableRequestsModal";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import ContactCardIcon from "@components/Icons/ContactCardIcon";
import DocGraphIcon from "@components/Icons/DocGraphIcon";
import DocInvoiceIcon from "@components/Icons/DocInvoiceIcon";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import EventIcon from "@components/Icons/EventIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import FormIcon from "@components/Icons/FormIcon";
import HouseIcon from "@components/Icons/HouseIcon";
import InboxIcon from "@components/Icons/InboxIcon";
import LogIcon from "@components/Icons/LogIcon";
import PayrollIcon from "@components/Icons/PayrollIcon";
import ServicesIcon from "@components/Icons/ServicesIcon";
import ShieldIcon from "@components/Icons/ShieldIcon";
import TemplateCreationIcon from "@components/Icons/TemplateCreationIcon";
import TemplateIcon from "@components/Icons/TemplateIcon";
import SidebarProfile from "@components/Sidebar/SidebarProfile";

import HelpButton from "./HelpButton";
import SidebarButton, { SidebarButtonProps } from "./SidebarButton";
import SidebarPracticeButton from "./SidebarPracticeButton";
import UnfoldingSidebarButtonWrapper from "./UnfoldingSidebarButtonWrapper";

const NavigationBar = ({
  isOpen,
  displayMobileSideBar,
  handleClickTrialButton,
}: {
  isOpen: boolean;
  displayMobileSideBar: () => void;
  handleClickTrialButton: () => void;
}) => {
  const { oid } = useAuth();
  const [hasAccountsPayableFeature] = useHasFeature(
    oid,
    FeatureNames.accountsPayable
  );
  const [hasOnTrackFeature] = useHasFeature(oid, FeatureNames.onTrack);
  const { hasFullAccess, hasElevatedAccess } = useAccessType();
  const [hasUsageBasedPackageFeature] = useHasFeature(
    oid,
    FeatureNames.usageBasedPackage
  );

  const canViewReconciliation = hasUsageBasedPackageFeature && hasFullAccess;
  const canViewServices = hasElevatedAccess;
  const { isReviewer } = useGetBillableReviewerConditional();
  const { data: billableRequestWaitingForReviewCount } =
    useGetBillableRequestNumbers({});

  const { hasUnread, count } = useUnreadChannels();

  const renderCalendarButton = (extended: boolean = false) => {
    const navItems = compact([
      {
        href: "/appointments",
        label: "Appointments",
        Icon: EventCreationIcon,
      },
      {
        href: "/schedulers",
        label: "Schedulers",
        Icon: TemplateCreationIcon,
      },
      {
        href: "/availabilities",
        label: "Availabilities",
        Icon: EventIcon,
      },
      isDevOrPreviewEnvironment && {
        href: "/group-schedulers",
        label: "Group schedulers",
        Icon: TemplateCreationIcon,
      },
    ]) as SidebarButtonProps[];

    return (
      <UnfoldingSidebarButtonWrapper
        title="Calendar"
        Icon={CalendarIcon}
        extended={extended}
        navItems={navItems}
      />
    );
  };

  const renderServicesButton = (extended: boolean = false) => {
    if (!canViewServices && !canViewReconciliation) return null;

    const navItems = compact([
      canViewServices && {
        href: "/services/templates",
        label: "Templates",
        Icon: TemplateIcon,
      },
      canViewServices && {
        href: "/transactions",
        label: "Transactions",
        Icon: DocInvoiceIcon,
      },
      canViewReconciliation && {
        href: "/reconciliation",
        label: "Reconciliation",
        Icon: DocGraphIcon,
      },
    ]) as SidebarButtonProps[];

    return (
      <UnfoldingSidebarButtonWrapper
        title="Services"
        Icon={ServicesIcon}
        extended={extended}
        navItems={navItems}
      />
    );
  };

  const renderPayrollButton = (extended: boolean = false) => {
    const canAccessBillableRequests = isReviewer;
    const canAccessPayPeriods = hasFullAccess && hasAccountsPayableFeature;

    if (!canAccessBillableRequests && !canAccessPayPeriods) return null;

    if (canAccessBillableRequests && !canAccessPayPeriods) {
      return (
        <SidebarButton
          href="/billable-requests"
          label="Billable requests"
          Icon={LogIcon}
          extended={extended}
        />
      );
    } else if (canAccessPayPeriods && !canAccessBillableRequests) {
      return (
        <SidebarButton
          href="/pay-periods"
          label="Pay periods"
          Icon={DocInvoiceIcon}
          extended={extended}
        />
      );
    }

    const hasUnread =
      !!billableRequestWaitingForReviewCount &&
      billableRequestWaitingForReviewCount?.submitted > 0;
    const unreadCount = billableRequestWaitingForReviewCount?.submitted;

    const navItems = compact([
      canAccessPayPeriods && {
        href: "/pay-periods",
        label: "Pay periods",
        Icon: DocInvoiceIcon,
      },
      canAccessBillableRequests && {
        href: "/billable-requests",
        label: "Billable requests",
        Icon: LogIcon,
        hasUnread,
        unreadCount,
      },
    ]) as SidebarButtonProps[];

    return (
      <UnfoldingSidebarButtonWrapper
        title="Payroll"
        Icon={PayrollIcon}
        extended={extended}
        navItems={navItems}
        hasUnread={hasUnread}
        unreadCount={unreadCount}
      />
    );
  };

  const renderContactsButton = (extended: boolean = false) => {
    const sharedProps = {
      href: "/contacts",

      hasUnread,
      unreadCount: count,
    };

    if (!hasOnTrackFeature)
      return (
        <SidebarButton
          {...sharedProps}
          label="Contacts"
          extended={extended}
          Icon={ClientIcon}
        />
      );
    return (
      <UnfoldingSidebarButtonWrapper
        title="Contacts"
        Icon={ClientIcon}
        extended={extended}
        navItems={[
          { ...sharedProps, Icon: ContactCardIcon, label: "Clients" },
          {
            href: "/on-track",
            label: "On track",
            Icon: ShieldIcon,
          },
        ]}
      />
    );
  };

  return (
    <>
      <Transition show={isOpen}>
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40 bg-grey-100/50">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative flex-1 flex flex-col max-w-xs w-full bg-grey-950"
            >
              <div className="absolute top-0 right-0 -mr-14 p-1">
                <button
                  className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                  aria-label="Close sidebar"
                  onClick={displayMobileSideBar}
                >
                  <svg
                    className="h-6 w-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-1 h-0 pt-9 pb-4 overflow-x-visible overflow-y-scroll">
                <SidebarPracticeButton
                  shouldShrink={false}
                  onClickTrialButton={handleClickTrialButton}
                />
                <nav className="mt-5 px-4 space-y-1 z-0">
                  <SidebarButton href="/" Icon={HouseIcon} label="Home" />
                  {renderCalendarButton()}
                  {renderContactsButton()}
                  {renderServicesButton()}
                  <SidebarButton href="/forms" Icon={FormIcon} label="Forms" />
                  <SidebarButton
                    href="/library"
                    Icon={FolderIcon}
                    label="Library"
                  />
                  <SidebarButton href="/inbox" Icon={InboxIcon} label="Inbox" />
                  {renderPayrollButton()}
                </nav>
              </div>
              <div className="flex flex-col shrink-0 px-4">
                <HelpButton />
              </div>
              <SidebarProfile shouldShrink={false} className="mx-4 mt-4 pb-6" />
            </Transition.Child>
            <div className="shrink-0 w-14"></div>
          </div>
        </div>
      </Transition>

      <div className="hidden sm:flex lg:shrink-0 flex-col w-20 lg:w-64 bg-grey-950">
        <SidebarPracticeButton
          className="pt-9"
          onClickTrialButton={handleClickTrialButton}
        />
        <nav className="flex-1 px-4 space-y-1 overflow-y-auto overflow-x-hidden pt-5">
          <SidebarButton
            href="/"
            Icon={HouseIcon}
            label="Home"
            extended
            className="my-0"
          />
          {renderCalendarButton(true)}
          {renderContactsButton(true)}
          {renderServicesButton(true)}
          <SidebarButton href="/forms" Icon={FormIcon} label="Forms" extended />
          <SidebarButton
            href="/library"
            Icon={FolderIcon}
            label="Library"
            extended
          />
          <SidebarButton
            href="/inbox"
            Icon={InboxIcon}
            label="Inbox"
            extended
          />
          {renderPayrollButton(true)}
        </nav>
        <div className="flex flex-col gap-4 pb-6">
          <div className="px-4 space-y-1">
            <HelpButton extended />
          </div>
          <SidebarProfile className="mx-4" />
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
