import {
  ChangeEvent,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContextProps } from "react-beautiful-dnd";
import classNames from "classnames";
import { isBoolean, noop } from "lodash";

import {
  HighlightedTextContextProvider,
  useHighlightedTextContext,
} from "@contexts/highlighted";
import useHubspotWidget from "@hooks/use-hubspot-widget";

import { Checkbox } from "@components/Form/Checkbox";

import FormDragAndDrop from "./FormDragAndDrop";
import LoadingSpinner from "./LoadingSpinner";
import { Trigger } from "./Trigger";

type SelectableList<T extends { id: string }> = {
  selected: T[];
  selectedIds: string[];
  clearSelected: () => void;
  toggleSelect: (value: T) => void;
  addItem: (value: T) => void;
  removeItem: (value: T) => void;
  totalSelected: number;
  getItemClasses: (
    item: T,
    options?: {
      withBorder?: boolean;
      withoutHover?: boolean;
    }
  ) => string;
  selectedAll: boolean;
  setSelectedAll: (value: boolean) => void;
};

const SelectableListContext = createContext({
  selected: [] as { id: string }[],
  selectedIds: [] as string[],
  clearSelected: noop,
  toggleSelect: noop,
  addItem: noop,
  removeItem: noop,
  totalSelected: 0,
  getItemClasses: noop as any,
  selectedAll: false,
  setSelectedAll: noop,
});

export const useSelectableListContext = (): SelectableList<{ id: string }> =>
  useContext(SelectableListContext);

export const SelectableListContextProvider: React.FC<{
  children: ReactNode;
  defaultSelected?: { id: string }[];
}> = ({ children, defaultSelected }) => {
  const [selected, setSelected] = useState<{ id: string }[]>(
    defaultSelected || []
  );
  const [selectedAll, setSelectedAll] = useState<undefined | boolean>();
  const selectedIds = useMemo(() => selected.map((it) => it.id), [selected]);

  const clearSelected = useCallback(() => {
    setSelected([]);
  }, []);

  const toggleSelect = useCallback((value: { id: string }) => {
    setSelected((ex) =>
      ex.map((item) => item.id).includes(value.id)
        ? ex.filter((x) => x.id !== value.id)
        : [...ex, value]
    );
  }, []);

  const addItem = useCallback((value: { id: string }) => {
    setSelected((ex) => [...ex, value]);
  }, []);

  const removeItem = useCallback((value: { id: string }) => {
    setSelected((ex) => ex.filter((x) => x.id !== value.id));
  }, []);

  const getItemClasses = useCallback(
    (
      item: { id: string },
      { withBorder = true, withoutHover = false } = {}
    ) => {
      const checked = selectedIds.includes(item.id);
      return classNames(
        withBorder && "border-t first:border-t-0 border-gray-200",
        !withoutHover && "can-hover:hover:bg-accent/30",
        "group relative  focus:outline-none focus:bg-accent/30 transition duration-150 ease-in-out",
        "flex sm:items-center",
        checked && "bg-accent/30"
      );
    },
    [selectedIds]
  );

  return (
    <SelectableListContext.Provider
      value={{
        selected,
        clearSelected,
        toggleSelect,
        totalSelected: selected.length,
        selectedIds,
        getItemClasses,
        addItem,
        removeItem,
        selectedAll,
        setSelectedAll,
      }}
    >
      <HighlightedTextContextProvider>
        {children}
      </HighlightedTextContextProvider>
    </SelectableListContext.Provider>
  );
};

export interface ListGrouping<T> {
  name: string;
  predicate: (item: T) => boolean;
}

interface SelectableListProps<T> {
  items?: T[];
  groups?: ListGrouping<T>[];
  rowRenderer: (item: T, index: number) => any;
  selectable: boolean;
  showMultiSelect?: boolean;
  header?: ReactNode;
  emptyList?: ReactNode;
  emptySearch?: ReactNode;
  loadingPlaceholder?: ReactNode;
  alwaysShowCheckbox?: boolean;
  showAll?: boolean;
  styleOptions?: {
    withBorder?: boolean;
    withoutHover?: boolean;
  };
  isLoading?: boolean;
  loadByScreenSize?: boolean;
  trigger?: {
    onIntersection: () => void;
    intersectionRootSelector?: string;
    className?: string;
  };
  listItemClassNames?: string;
  filterInPlace?: boolean;
  headerClassNames?: string;
}

type SelectableListItemProps<T> = {
  index: number;
  item: T;
  className?: string;
  withBorder?: boolean;
} & Pick<
  SelectableListProps<T>,
  | "rowRenderer"
  | "selectable"
  | "alwaysShowCheckbox"
  | "items"
  | "listItemClassNames"
>;

const SelectableListItem = <
  T extends {
    id: string;
    isSelectionDisabled?: boolean;
  },
>({
  items,
  item,
  rowRenderer,
  selectable,
  index,
  alwaysShowCheckbox,
  listItemClassNames,
}: SelectableListItemProps<T>) => {
  const { toggleSelect, selectedIds, addItem, removeItem, selectedAll } =
    useSelectableListContext();

  const checked = selectedIds.includes(item.id);

  useEffect(() => {
    if (!isBoolean(selectedAll)) return;

    if (selectedAll) {
      !checked && !item.isSelectionDisabled && addItem(item);
    } else {
      removeItem(item);
    }
  }, [selectedAll]);

  const getRowRange = (
    rows: string[],
    currentIndex: number,
    selectedIndex: number
  ) => {
    const rangeStart =
      selectedIndex > currentIndex ? currentIndex : selectedIndex;
    const rangeEnd = rangeStart === currentIndex ? selectedIndex : currentIndex;
    return rows.slice(rangeStart, rangeEnd + 1);
  };

  return (
    <div className={classNames("flex-1 flex items-center", listItemClassNames)}>
      {selectable && (
        <div
          data-id={item.id}
          className={
            alwaysShowCheckbox
              ? ""
              : classNames(
                  !checked && "md:invisible group-hover:visible",
                  checked && "!visible"
                )
          }
        >
          <Checkbox
            className="mx-2 sm:mx-4 sm:mr-0"
            disabled={item.isSelectionDisabled}
            defaultChecked={checked}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              // @ts-expect-error: shift key is defined for click events
              if (event.nativeEvent.shiftKey) {
                const allIds = items?.map((it) => it.id) ?? [];

                const rowsToToggle = getRowRange(
                  allIds,
                  index,
                  items?.findIndex((it) => it.id === selectedIds[0]) ?? 0
                );

                rowsToToggle.forEach((id, toogleIndex: number) => {
                  const rowItem = items?.find((it) => it.id === id);
                  const shouldIgnore =
                    toogleIndex === 0 ||
                    toogleIndex === rowsToToggle.length - 1;

                  !shouldIgnore &&
                    rowItem &&
                    !rowItem.isSelectionDisabled &&
                    toggleSelect(rowItem);
                });
              }

              toggleSelect(item);
            }}
          />
        </div>
      )}
      <div className="flex-1">{rowRenderer(item, index)}</div>
    </div>
  );
};

const ITEM_SIZE = 80;

export const SelectableList = <T extends { id: string }>({
  items,
  emptyList = null,
  emptySearch = null,
  loadingPlaceholder = null,
  groups,
  showAll = false,
  isLoading = false,
  trigger,
  styleOptions = {},
  showMultiSelect = false,
  loadByScreenSize = true,
  header,
  filterInPlace = true,
  headerClassNames,
  ...rest
}: SelectableListProps<T>) => {
  const { getItemClasses, selectedAll, setSelectedAll } =
    useSelectableListContext();
  const { highlight, filter } = useHighlightedTextContext();
  const [visibleCount, setVisibleCount] = useState<number>(
    Math.ceil(window.innerHeight / ITEM_SIZE)
  );

  const ref = useRef<HTMLDivElement>(null);

  const filtered = useMemo(() => {
    return items && filterInPlace ? filter(items) : items;
  }, [filter, items, filterInPlace]);

  useEffect(() => {
    const res = ref.current?.closest("main.main");

    if (!res || !loadByScreenSize) return;

    const handler: EventListenerOrEventListenerObject = () => {
      if (!filtered) return;
      if (res.scrollHeight < res.scrollTop + res.clientHeight + ITEM_SIZE) {
        setVisibleCount((ex) =>
          ex < filtered?.length ? ex + 12 : Math.max(12, filtered?.length)
        );
      }
    };

    res.addEventListener("scroll", handler);
    return () => res.removeEventListener("scroll", handler);
  }, [filtered]);

  useHubspotWidget();

  const groupedData = useMemo(() => {
    if (!filtered) return [];

    const filteredByScreen = loadByScreenSize
      ? filtered?.slice(0, visibleCount)
      : filtered;

    if (showAll) return [{ name: "", items }];
    if (!groups)
      return [
        {
          name: "",
          items: filteredByScreen,
        },
      ];

    const base = groups.reduce((acc, gr) => ({ ...acc, [gr.name]: [] }), {});

    const groupedData = filteredByScreen.reduce((acc: any, item: T) => {
      const matching = groups.find((g) => g.predicate(item));
      if (matching) {
        const name = matching.name;
        return { ...acc, [name]: [...acc[name], item] };
      }
      return acc;
    }, base) as { [key: string]: T[] };

    return Object.entries(groupedData || {})
      .filter(([, items]) => items.length)
      .map(([name, items]) => ({ name, items }));
  }, [filtered, groups, items, showAll, visibleCount]);

  if (!items || (items.length === 0 && isLoading)) {
    return loadingPlaceholder ? (
      <>{loadingPlaceholder}</>
    ) : (
      <LoadingSpinner className="mx-auto" />
    );
  }

  if (highlight && !filtered?.length) return <>{emptySearch}</>;

  if (items.length === 0) return <>{emptyList}</>;

  return (
    <div ref={ref}>
      {(showMultiSelect || header) && !!items?.length && (
        <div
          className={classNames(
            "flex items-center border-b py-2 pb-2 z-10 mb-2",
            headerClassNames
          )}
        >
          {showMultiSelect && (
            <Checkbox
              className="mx-2 sm:mx-4 sm:mr-0"
              defaultChecked={selectedAll}
              value={selectedAll}
              onChange={() => setSelectedAll(!selectedAll)}
            />
          )}
          {header}
        </div>
      )}
      {groupedData?.map((group, i) => {
        return (
          <div key={`group-${i}`}>
            {group.name && (
              <div className="text-foreground font-medium pt-2 pb-2">
                {group.name}
              </div>
            )}
            <div>
              {group.items?.map((item, index) => (
                <div
                  key={`group-item-${item.id}-${index}`}
                  className={getItemClasses(item, styleOptions)}
                >
                  <SelectableListItem
                    items={items}
                    item={item}
                    key={item.id}
                    index={index}
                    selectedAll={selectedAll}
                    {...rest}
                  />
                </div>
              ))}
              {trigger && group.items?.length > 0 && (
                <Trigger
                  isLoading={isLoading}
                  onIntersection={trigger.onIntersection}
                  intersectionRootSelector={trigger.intersectionRootSelector}
                  loadingPlaceholder={loadingPlaceholder}
                  className={trigger.className}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

type DnDSelectableListProps<T> = {
  onDragEnd: DragDropContextProps["onDragEnd"];
  onDragStart: DragDropContextProps["onDragStart"];
  isDragDisabled?: boolean;
  isSortDisabled?: boolean;
} & SelectableListProps<T>;

export const DnDSelectableList = <T extends { id: string }>({
  onDragEnd,
  onDragStart,
  items,
  isDragDisabled,
  isSortDisabled,
  ...rest
}: DnDSelectableListProps<T>) => {
  const [draggingOver, setDraggingOver] = useState<string>();
  const [draggedItemId, setDraggedItemId] = useState<string>();
  const { selectedIds } = useSelectableListContext();
  const onDragUpdate: DragDropContextProps["onDragUpdate"] = useCallback(
    (update) => {
      setDraggingOver(update.combine?.draggableId);
    },
    []
  );

  const onDragEndWrapper: DragDropContextProps["onDragEnd"] = useCallback(
    async (result, provided) => {
      setDraggingOver(undefined);
      setDraggedItemId(undefined);
      await onDragEnd(result, provided);
    },
    [onDragEnd]
  );

  const onDragStartWrapper: DragDropContextProps["onDragStart"] = useCallback(
    async (initial, provided) => {
      setDraggedItemId(initial.draggableId);
      await onDragStart?.(initial, provided);
    },
    [onDragStart]
  );

  const { getItemClasses } = useSelectableListContext();

  const getItemClassname = useCallback(
    (item: any) => {
      return classNames(
        getItemClasses(item),
        draggingOver === item.id &&
          item.type === "folders" &&
          item.status !== "system" &&
          "bg-green-900",
        draggedItemId === item.id && "border-t-0",
        draggedItemId &&
          item.type === "folders" &&
          item.status === "system" &&
          "pointer-events-none"
      );
    },
    [getItemClasses, draggingOver, draggedItemId]
  );

  return (
    <FormDragAndDrop
      getItemClassname={getItemClassname}
      isCombineEnabled={true}
      onDragUpdate={onDragUpdate}
      onDragStart={onDragStartWrapper}
      fields={items}
      onDragEnd={onDragEndWrapper}
      isDragDisabled={isDragDisabled}
      isSortDisabled={isSortDisabled}
    >
      {(item, index) => (
        <>
          <SelectableListItem item={item} index={index} {...rest} />
          {item.id === draggedItemId && selectedIds.length > 1 && (
            <div className="h-10 w-10 -top-5 -right-5 absolute flex items-center justify-center bg-action-800 rounded-full">
              +{selectedIds.length - 1}
            </div>
          )}
        </>
      )}
    </FormDragAndDrop>
  );
};
